import { get, post, http } from './request'
import { utilities } from '@/mixins/js-helpers'

export default {
  loadUserJobHistory: (path, success, failure) => {
    return get(
      path,
      response => success(response),
      error => failure(error)
    )
  },
  loadUsers: (path, success, failure) => {
    return get(
      path,
      response => success(response),
      error => failure(error)
    )
  },
  loadUser: (userId, success, failure) => {
    return get(
      `/api/user/${userId}`,
      response => success(response),
      error => failure(error)
    )
  },
  loadTechnicianList: (success, failure) => {
    return get(
      '/api/technicians',
      response => success(response),
      error => failure(error)
    )
  },
  searchTechnician: (search, success, failure) => get(
    `/api/technicians/search`, success, failure, { params: search }
  ),
  loadAdminSelectList: (success, failure) => get(
    '/api/administrator-list',
    response => success(response),
    error => failure(error)
  ),
  loadTechnicianSelectList: (success, failure) => get(
    '/api/technicians-select-list',
    response => success(response),
    error => failure(error)
  ),
  loadUserList: (success, failure) => {
    return get(
      '/api/users/list',
      response => success(response),
      error => failure(error),
    )
  },
  loadUserSiteIds: (userId, success, failure) => {
    return get(
        `/api/user/${userId}/site-ids`,
        response => success(response),
        error => failure(error),
    )
  },
  loadActiveUserList: (query, success, failure) => {
    return get(
      '/api/users/active/list',
      response => success(response),
      error => failure(error),
      { params: query }
    )
  },
  storeTechnician: (user, success, failure) => {
    return post(
      '/api/technician',
      user,
      response => success(response),
      error => failure(error)
    )
  },
  storeAdmin: (user, success, failure) => {
    return post(
      '/api/admin',
      user,
      response => success(response),
      error => failure(error)
    )
  },
  storeClientUser: (payload, success, failure) => {
    return post(
      `/api/client/${payload.clientId}/user`,
      payload.user,
      response => success(response),
      error => failure(error)
    )
  },
  updateClientUser: (payload, success, failure) => {
    return post(
      `/api/client/${payload.clientId}/user/${payload.user.id}`,
      payload.user,
      response => success(response),
      error => failure(error)
    )
  },
  save: (user, success, failure) => {
    return post(
      `/api/user/${user.id}`,
      user,
      response => success(response),
      error => failure(error)
    )
  },
  uploadProfilePic: (payload, success, failure) => {
    return post(
      `/api/user/${payload.user}/profile/picture`,
      utilities.formData(payload),
      response => success(response),
      error => failure(error)
    )
  },
  resetPassword: (payload, success, failure) => post(
    `/api/user/${payload.userId}/reset`,
    payload.reset,
    response => success(response),
    error => failure(error)
  ),
  unlockUser: (payload, success, failure) => post(
      `/api/users/unlock/${payload.userId}`,
      payload.reset,
      response => success(response),
      error => failure(error)
  ),
  disableMFA: (payload, success, failure) => post(
      `/api/users/${payload.userId}/disable-mfa`,
      payload.reset,
      response => success(response),
      error => failure(error)
  ),
  loadStats: (userId, success, failure) => get(
    `/api/user/${userId}/stats`,
    success, failure
  ),
  loadLatestLocations: (userId, success, failure) => get(
    `/api/user/${userId}/latest-locations`,
    success, failure
  ),
  deactivate: (userId, success, failure) => post(
    `/api/user/${userId}/deactivate`,
    {},
    success, failure
  ),
  activate: (userId, success, failure) => post(
    `/api/user/${userId}/activate`,
    {},
    success, failure
  ),
  subscribeToChannel: ({ channel, user }, success, failure) => post(
    `/api/notifications/subscribe/${user}/${channel}`,
    {}, success, failure
  ),

  unsubscribeFromChannel: ({ channel, user }, success, failure) => post(
    `/api/notifications/unsubscribe/${user}/${channel}`,
    {}, success, failure
  ),

  downloadJobHistoryByDateRange: (payload, success, failure) => {
    return http({
      method: 'get',
      url: `/api/user/${payload.userId}/job-report`,
      params: payload,
      responseType: 'blob'
    }
    ).then(response => success(response))
      .catch(error => failure(error))
  },

  changeEmail: (payload, success, failure) => post(
    `/api/user/${payload.userId}/email`,
    payload.email,
    success, failure
  ),

  upgradeToAdmin: (payload, success, failure) => post(
    `/api/user/${payload.userId}/admin-upgrade`,
    payload.email,
    success, failure
  ),

  downgradeToTechnician: (payload, success, failure) => post(
    `/api/user/${payload.userId}/technician-downgrade`,
    payload.email,
    success, failure
  ),

  syncSites: (user, payload, success, failure) => post(
    `/api/user/${user}/sync-sites`,
    payload, success, failure
  ),
  deselectAllSites: (userId, success, failure) => post(
      `/api/user/${userId}/disallow-all-sites`,
      {}, success, failure
  ),
  selectAllSites: (userId, success, failure) => post(
    `/api/user/${userId}/allow-all-sites`,
    {}, success, failure
  )
}
