import {get, http, post} from './request'

export default {
    deleteInspection: (inspectionId, success, failure) => post(
        `/api/inspection/${inspectionId}/delete`,
        {},
        response => success(response),
        error => failure(error),
    ),

    recover: (inspectionId, success, failure) => post(
        `/api/inspection/${inspectionId}/recover`,
        {},
        response => success(response),
        error => failure(error),
    ),

    load: (inspectionId, success, failure) => get(
        `/api/inspection/${inspectionId}`,
        response => success(response),
        error => failure(error),
    ),

    loadInspectionCount: (payload, success, failure) => get(
        payload.path,
        response => success(response),
        error => failure(error),
    ),

    downloadFailedChecks: (filters, success, failure) => {
        return http({
                method: 'get',
                url: `/api/failed-checks/download`,
                params: filters,
                responseType: 'blob'
            }
        ).then(response => success(response))
            .catch(error => failure(error))
    },

    getAssetUpcomingInspections: (assetId, success, failure) => get(
        `/api/assets/${assetId}/upcoming-inspections`,
        response => success(response),
        error => failure(error),
    ),

    downloadFilteredIndexReport: (filters, success, failure) => {
        return http({
            method: 'get',
            url: `/api/inspections/report`,
            params: filters,
            responseType: 'blob',
        }).then(response => success(response)).catch(error => failure(error))
    },

    downloadFilteredIndexReportAsExcel: (filters, success, failure) => {
        return http({
                method: 'get',
                url: `/api/inspections/report/xlsx`,
                params: filters,
                responseType: 'blob',
            },
        ).then(response => success(response)).catch(error => failure(error))
    },

    downloadUpcomingInspectionsReportAsExcel: (filters, success, failure) => {
        return http({
                method: 'get',
                url: `/api/upcoming-inspections/download-xls`,
                params: filters,
                responseType: 'blob',
            },
        ).then(response => success(response)).catch(error => failure(error))
    },

    downloadOverdueInspectionsReport: (filters, success, failure) => {
        return http({
                method: 'get',
                url: `/api/overdue-inspections/download`,
                params: filters,
                responseType: 'blob',
            },
        ).then(response => success(response)).catch(error => failure(error))
    },

    downloadChecklistInspectionsReportAsExcel: (filters, success, failure) => {
        return http({
                method: 'get',
                url: `/api/inspections/accumulated-checkpoint-report-download-xls`,
                params: filters,
                responseType: 'blob',
            },
        ).then(response => success(response)).catch(error => failure(error))
    },

    downloadInspectionSummaryReport: (payload, success, failure) => {
        return http({
                method: 'post',
                url: `/api/inspections/${payload.inspectionId}/report-summary`,
                data: payload.options,
                responseType: 'blob'
            }
        ).then(response => success(response))
            .catch(error => failure(error))
    },

    emailFilteredIndexReportAsExcel: (filters, success, failure) => {
        return http({
                method: 'get',
                url: `/api/inspections/report/xlsx/email`,
                params: filters,
                responseType: 'blob',
            },
        ).then(response => success(response)).catch(error => failure(error))
    },

    downloadFilteredIndexReportAsCsv: (filters, success, failure) => {
        return http({
                method: 'get',
                url: `/api/inspections/report/csv`,
                params: filters,
                responseType: 'blob',
            },
        ).then(response => success(response)).catch(error => failure(error))
    },

    emailFilteredIndexReportAsCsv: (filters, success, failure) => {
        return http({
                method: 'get',
                url: `/api/inspections/report/csv/email`,
                params: filters,
                responseType: 'blob',
            },
        ).then(response => success(response)).catch(error => failure(error))
    },
}
